import Box from '@src/components/atoms/Box'
import React from 'react'

import { services } from '@src/config/services'
import '@src/theme/services.sass'

const downloadIcons = {
  android: require('../images/downloadIcons/google-play.svg'),
  ios: require('../images/downloadIcons/appstore.svg'),
}

type ServiceListItemAppProps = {
  android: string | undefined
  description: string
  icon: any
  ios: string | undefined
  name: string
}
const ServiceListItemApp: React.FC<ServiceListItemAppProps> = (props) => (
  <div className="ServiceListItemApp">
    <div className="ServiceAppLeft">
      <div className="ServiceAppLogo">
        <img src={props.icon} />
      </div>
    </div>
    <div className="ServiceAppRight">
      <h3 className="ServiceAppName">{props.name}</h3>
      <p className="ServiceAppDescription" dangerouslySetInnerHTML={{ __html: props.description }} />
      <div className="downloadIcons">
        {props.ios && (
          <a href={props.ios}>
            <img src={downloadIcons.ios} />
          </a>
        )}
        {props.android && (
          <a href={props.android}>
            <img src={downloadIcons.android} />
          </a>
        )}
      </div>
    </div>
  </div>
)

const ServiceList = () => (
  <div className="ServiceList">
    <div>
      <h2>スマホ用アプリ</h2>
      {services.map((service) => (
        <ServiceListItemApp
          icon={service.icon}
          name={service.name}
          description={service.description}
          ios={service.store.ios?.appStore}
          android={service.store.android?.playStore}
        />
      ))}
    </div>
    <div>
      <h2>アプリケーション開発</h2>
    </div>
    <div>
      <h2>Web開発 &amp; コンサルティング</h2>
    </div>
  </div>
)

const ServicePage: React.FC<{}> = () => (
  <Box>
    <h1 className="pageTitle">Our Services</h1>
    <ServiceList />
  </Box>
)

export default ServicePage
